import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { errorManager, ErrorService } from '@vfit/shared/data-access';
import { ICodeResponse } from '@vfit/shared/hooks';
import { trackView } from '@vfit/shared/data-access';
import * as S from './clickToCall.style';
import { callMeBackSchema, callMeSchema } from './clickToCall.validation';
import { ICallMeBackSchema, IClickToCall } from './clickToCall.models';
import { ClickToCallCardSubmitted } from '../ClickToCallCardSubmitted/clickToCallCardSubmitted';
import { ClickToCallCardToSubmit } from '../ClickToCallCardToSubmit/clickToCallCardToSubmit';
import { getTrackError } from '../../vola.utils';
import AppClickToCallChoice from '../AppClickToCallChoice/appClickToCallChoice';

const ClickToCall: React.FC<IClickToCall> = ({
  onTrackSubmit,
  setTelephoneNumber,
  setTimeSlot,
  contactTimes,
  isSuccess,
  isLoading,
  isError,
  errorTitle,
  errorMessage = '',
  codeResponse,
  clickToCallData,
  volaService,
  defaultNumber,
  handleClose,
  isAppConfirmedActivation,
  onAppConfirmActivation,
  cmsAppClickToCallChoice,
}) => {
  const isCallMeBack = volaService === 'cmb';
  const [submitted, setSubmitted] = useState(false);
  const [inputs, setInputs] = useState({});

  const form = useForm<ICallMeBackSchema>({
    resolver: yupResolver(isCallMeBack ? callMeBackSchema() : callMeSchema()),
    mode: 'all',
  });

  const handleSubmit = () => {
    setInputs(form.getValues());
    setTelephoneNumber(form.getValues().phoneNumber);
    if (isCallMeBack && form.getValues().timeSlot) {
      setTimeSlot(form.getValues().timeSlot || '');
    }
    setSubmitted(true);
  };

  useEffect(() => {
    if (isError && codeResponse && errorMessage) {
      trackView({
        ...getTrackError(codeResponse as ICodeResponse, errorTitle, errorMessage),
        ignore_base_page_name: true,
      });
      errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
        secondButtonText: '',
        isButtonSticky: true,
        title: errorTitle,
        message: errorMessage,
        errorCode: codeResponse,
        disableTrack: true,
      });
    }
  }, [isError, codeResponse, errorTitle, errorMessage]);

  useEffect(() => {
    if (submitted && isSuccess) {
      if (onTrackSubmit) {
        onTrackSubmit(`${isCallMeBack ? 'CMB:TYP' : 'CMN:TYP'}`);
      }
    }
  }, [isSuccess, submitted]);

  useEffect(() => {
    if (onTrackSubmit) {
      if (volaService === 'cmb') {
        onTrackSubmit('CMB');
      } else if (volaService === 'cmn') {
        onTrackSubmit('CMN');
      }
    }
  }, [volaService]);

  if (submitted && isSuccess) {
    return (
      <S.ClickToCallCardFlow>
        <ClickToCallCardSubmitted
          isCallMeNow={!isCallMeBack}
          clickToCallData={clickToCallData}
          phoneNumber={defaultNumber || (inputs as ICallMeBackSchema)?.phoneNumber}
          timeSlot={(inputs as ICallMeBackSchema)?.timeSlot}
        />
      </S.ClickToCallCardFlow>
    );
  }

  if (submitted && isError) {
    return (
      <S.ClickToCallCardFlow>
        <ClickToCallCardSubmitted
          isCallMeNow={!isCallMeBack}
          clickToCallData={clickToCallData}
          phoneNumber={defaultNumber || (inputs as ICallMeBackSchema)?.phoneNumber}
          timeSlot={(inputs as ICallMeBackSchema)?.timeSlot}
        />
      </S.ClickToCallCardFlow>
    );
  }

  if ((defaultNumber && !submitted) || (defaultNumber && submitted && isLoading))
    return (
      <AppClickToCallChoice
        cmsAppClickToCallChoice={cmsAppClickToCallChoice}
        onActive={() => {
          onAppConfirmActivation?.();
          setSubmitted(true);
        }}
        onClose={handleClose}
        isLoading={isLoading}
        isAppConfirmedActivation={isAppConfirmedActivation}
      />
    );

  return (
    <S.ClickToCallCardFlow>
      <ClickToCallCardToSubmit
        contactTimes={contactTimes}
        clickToCallData={clickToCallData}
        form={form}
        handleSubmit={handleSubmit}
        callMeBack={isCallMeBack}
        isLoading={isLoading}
      />
    </S.ClickToCallCardFlow>
  );
};

export default ClickToCall;
